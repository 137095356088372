import React from "react"

import ProcessDiscover from "../../../../Elements/Images/ProcessDiscover"
import ProcessDesign from "../../../../Elements/Images/ProcessDesign"
import ProcessDevelop from "../../../../Elements/Images/ProcessDevelop"

import LetterA from "../../../../Elements/SVG/LetterA"
import PaintPallet from "../../../../Elements/SVG/PaintPallet"
import PaintIpad from "../../../../Elements/SVG/PaintIpad"

export default [
  {
    title: "Discover",
    description:
      "Discovery processes are there for a reason. It’s so important to ensure fit and aligned values are there. We need to discover so much about you. Who’s the hero of your story? What is your why for getting up each morning and fighting this fight? What are your goals and dreams for the project? Where do you want your company to be 5 years from now and are you looking for a guide along the way?",
    bgImg: <ProcessDiscover />,
    icon: <LetterA />,
    steps: [
      "1. MEETING KICK OFF",
      "2. CREATIVE BRIEF",
      "3. QUOTE",
      "4. CONTRACT",
      "5. BEGIN",
    ],
  },
  {
    title: "Design",
    description:
      "There is a lot of collaborative work in this phase. We research, distill, create and connect ideas together based on what was uncovered. We only show you the best of the ideas, from the moodboard to the prototypes. Strong communication and user experience are the most critical aspects at this point. Attention to big and minute detail is critical.",
    bgImg: <ProcessDesign />,
    icon: <PaintPallet />,
    steps: [
      "6. WIREFRAME",
      "7. MOODBOARD",
      "8. DEEP WORK DESIGN",
      "9. UX | UI FLOW",
      "10. PROTOTYPE",
    ],
  },
  {
    title: "Develop",
    description:
      "This is where every web project really comes to life. Taking the designs and making sure that the code and technologies used, do everything they were meant to, for optimal functionality and clean code. We don’t use templates, ever, for our projects. It doesn’t make sense to us when every project and business is so different. You have objectives and our job is to reach them.",
    bgImg: <ProcessDevelop />,
    icon: <PaintIpad />,
    steps: [
      "11. DEVELOP",
      "12. TESTING",
      "13. LAUNCH",
      "14. TRAIN",
      "15. AFTER LAUNCH SUPPORT",
    ],
  },
]

import React from "react"
import BgImg from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const ProcessDesignStyled = styled(BgImg)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 12.5rem;
  padding: 0;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
`

const getData = graphql`
  {
    processDesign: file(relativePath: { eq: "web-process_2_design.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ProcessDesign = props => {
  const data = useStaticQuery(getData)
  const imageData = data.processDesign.childImageSharp.fluid
  return <ProcessDesignStyled Tag="div" fluid={imageData} />
}

export default ProcessDesign

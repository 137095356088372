import React from "react"

const PaintPallet = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 267.29 297.12">
        <g
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
        >
          <path d="M82.02 26.89c-31.28 3.7-71.14 20.44-77.59 85.68S64.32 272.24 147.23 284s99.66-74.36 99.66-74.36 3.61-50.72-13.26-78.9c-17.43-29.11-28.6-55.63-55.09-56.52-27.31-.93-27.66 19-50.63 19-22.57 0-23.06-31.7-24.84-47.61s-7.8-20.28-21.05-18.72z" />
          <circle cx="55.38" cy="98.74" r="17.59" />
          <circle cx="65.48" cy="163.68" r="17.59" />
          <circle cx="107.58" cy="215.04" r="17.59" />
          <circle cx="168.84" cy="236.65" r="17.59" />
          <ellipse
            cx="183.75"
            cy="140.15"
            rx="22.61"
            ry="30.48"
            transform="rotate(-17.77 176.229 152.911)"
          />
        </g>
        <g
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
        >
          <path
            fill="none"
            d="M263.19 58.24c-1.1 13.77-14.79 17.09-21.26 17.09s-12-2-18.44-11.65C208.32 40.93 255.57.58 254.66 4.23c-4 8.37-2.88 23.25 2.6 33.4 7.29 13.53 5.93 20.61 5.93 20.61z"
          />
          <path
            fill="none"
            d="M254.43 76.5L254.43 89.9 227.59 89.9 227.59 76.26 254.43 76.5z"
          />
          <path
            fill="#fff"
            d="M250.94 89.9c7.28 13.9 10.32 30.6 10.32 59.05s-10.32 137.7-10.32 137.7-.07 6.47-8.19 6.47c-13.36 0-9.12-8.36-9.12-8.36s-8.29-102.05-10.69-134.16c-1.96-26.27 8.22-61.27 8.22-61.27z"
          />
        </g>
      </svg>
    </div>
  )
}

export default PaintPallet

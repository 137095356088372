import React from "react"
import styled from "styled-components"

import techItems from "./StackItems/Items"
import StandardWrapper from "../../Shared/StandardWrapper"
import {
  headlineOne,
  headlineTwo,
  headlineThree,
  fontSizer,
  bodyCopy,
  colors,
} from "../../../Utilities"
import WebTechWordPress from "../../../Elements/Images/WebTechWordPress"
import WebTechContentful from "../../../Elements/Images/WebTechContentful"
import WebTechSanity from "../../../Elements/Images/WebTechSanity"
import WebTechStrapi from "../../../Elements/Images/WebTechStrapi"
import WebTechReact from "../../../Elements/Images/WebTechReact"
import WebTechGatsby from "../../../Elements/Images/WebTechGatsby"
import WebTechNext from "../../../Elements/Images/WebTechNext"
import WebTechNode from "../../../Elements/Images/WebTechNode"
import WebTechNetlify from "../../../Elements/Images/WebTechNetlify"
import WebTechZeit from "../../../Elements/Images/WebTechZeit"
import WebTechFirebase from "../../../Elements/Images/WebTechFirebase"

const TechStackSection = styled.section`
  .tech-stack-we-use {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &__main {
      width: 100%;
      margin: 0 0 2rem;
      text-align: center;

      h1 {
        ${headlineTwo};

        span {
          display: block;
        }
      }
    }

    &__headless {
      width: 100%;
      margin: 2rem 0;
    }

    &__title {
      width: 100%;
      text-align: center;

      h2 {
        ${headlineThree};
        ${fontSizer(2, 2.2, 76.8, 110, 3)}
        margin: 0;
        color: #5c5c5c;
      }
    }

    &__logos {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      padding: 2.5rem 0;

      @media (min-width: 768px) {
        padding: 0;
      }

      &--item {
        align-self: center;
        width: calc(50% - 8rem);
        margin: 0 4rem;

        @media (min-width: 768px) {
          width: calc(20% - 4rem);
          margin: 2rem;
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          max-width: 100%;
          object-fit: contain !important;
        }
      }
    }
  }

  .tech-intro {
    width: 100%;
    margin-bottom: 5rem;

    @media (min-width: 1025px) {
      width: calc(100% - 20rem);
      margin-right: auto;
      margin-left: auto;
    }
  }

  .tech-title {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 4rem;

    h2 {
      ${headlineOne};
      margin: 0;
      margin-bottom: 1rem;
      color: #3a3b3b;
      text-transform: uppercase;
    }

    h3 {
      ${headlineTwo};
      margin: 0;
      color: #5c5c5c;
    }
  }

  .tech-content {
    p {
      ${bodyCopy};
      color: #585858;

      a {
        color: ${colors.colorSecondary};

        &:hover {
          color: ${colors.colorAccent};
        }
      }
    }
  }

  .tech-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 75rem;
    margin: 0 auto;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }
  }
`

const TechBandIcon = styled.a`
  width: calc(20% - 3rem);
  margin: 2rem 1.5rem;

  @media (min-width: 768px) {
    width: calc(16.6666% - 6rem);
    margin: 2rem 3rem;
  }

  svg {
    fill: ${props => props.brandColor};
  }
`

const TechStack = () => {
  return (
    <TechStackSection>
      <StandardWrapper>
        <div className="tech-intro">
          <div className="tech-title">
            <h2>It doesn't always have to be WordPress</h2>
            <h3>
              We love WordPress, but we are also huge lovers of the JAMstack
            </h3>
          </div>
          <div className="tech-content">
            <p>
              What is the JAMstack? Well basically JAMstack is a different way
              of building a website. The JAM in Jamstack, is an acronym that
              stands for JavaScript, APIs, and Markdown. What that boils down to
              for our clients is much faster websites, better SEO ranking, more
              secure then traditional websites and a better experience for your
              end users. To build a website with the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://jamstack.org"
              >
                JAMstack
              </a>{" "}
              we use technologies like{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.gatsbyjs.org/"
              >
                GatsbyJS
              </a>
              ,{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://strapi.io/"
              >
                Strapi
              </a>
              ,{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.netlify.com/"
              >
                Netlify
              </a>
              , and we can even build you a headless{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wordpress.org/"
              >
                WordPress
              </a>{" "}
              CMS if you like.
            </p>
          </div>
        </div>

        <div className="tech-stack-we-use">
          <div className="tech-stack-we-use__main">
            <h1>
              So You're Interested In Building With The JAMstack,{" "}
              <span>Here's How We Do It.</span>
            </h1>
          </div>
          <div className="tech-stack-we-use__headless">
            <div className="tech-stack-we-use__title">
              <h2>What Headless CMS We Use</h2>
            </div>
            <div className="tech-stack-we-use__logos">
              <div className="tech-stack-we-use__logos--item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://strapi.io/"
                >
                  <WebTechStrapi />
                </a>
              </div>
              <div className="tech-stack-we-use__logos--item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wordpress.org/"
                >
                  <WebTechWordPress />
                </a>
              </div>
              <div className="tech-stack-we-use__logos--item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.contentful.com/"
                >
                  <WebTechContentful />
                </a>
              </div>
              <div className="tech-stack-we-use__logos--item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.sanity.io/"
                >
                  <WebTechSanity />
                </a>
              </div>
            </div>
          </div>

          <div className="tech-stack-we-use__headless">
            <div className="tech-stack-we-use__title">
              <h2>How We Build Your Website</h2>
            </div>
            <div className="tech-stack-we-use__logos">
              <div className="tech-stack-we-use__logos--item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://reactjs.org/"
                >
                  <WebTechReact />
                </a>
              </div>
              <div className="tech-stack-we-use__logos--item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.gatsbyjs.org/"
                >
                  <WebTechGatsby />
                </a>
              </div>
              <div className="tech-stack-we-use__logos--item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://nextjs.org/"
                >
                  <WebTechNext />
                </a>
              </div>
              <div className="tech-stack-we-use__logos--item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://nodejs.org/en/"
                >
                  <WebTechNode />
                </a>
              </div>
            </div>
          </div>
          <div className="tech-stack-we-use__headless">
            <div className="tech-stack-we-use__title">
              <h2>Where We Deploy Your Website</h2>
            </div>
            <div className="tech-stack-we-use__logos">
              <div className="tech-stack-we-use__logos--item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.netlify.com/"
                >
                  <WebTechNetlify />
                </a>
              </div>
              <div className="tech-stack-we-use__logos--item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://zeit.co/"
                >
                  <WebTechZeit />
                </a>
              </div>
              <div className="tech-stack-we-use__logos--item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://firebase.google.com/"
                >
                  <WebTechFirebase />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="tech-intro">
          <div className="tech-title">
            <h2>The Tech</h2>
            <h3>The process matters + so does the technology</h3>
          </div>
          <div className="tech-content">
            <p>
              Here are some of the technologies and tools that we use to create
              these modern, super slick websites. Switchback was conceived from
              a desire to see higher quality websites for businesses that know
              that matters.
            </p>
          </div>
        </div>
        <div className="tech-icons">
          {techItems.map((item, index) => {
            return (
              <TechBandIcon
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                href={item.link}
                className="tech-icon"
                title={item.title}
                brandColor={item.hex}
              >
                <div dangerouslySetInnerHTML={{ __html: item.svg }} />
              </TechBandIcon>
            )
          })}
        </div>
      </StandardWrapper>
    </TechStackSection>
  )
}

export default TechStack

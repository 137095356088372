import React from "react"
import styled from "styled-components"

import processItems from "./StackItems/process"
import StandardWrapper from "../../Shared/StandardWrapper"
import {
  bodyCopy,
  fontSizer,
  MedWrapper,
  headlineOne,
  headlineTwo,
} from "../../../Utilities"

const WebProcessSection = styled.section`
  margin-bottom: 5rem;

  @media (min-width: 1025px) {
    margin-bottom: 7.5rem;
  }

  .process-wrapper {
    ${MedWrapper};
  }

  .process-intro {
    width: 100%;

    @media (min-width: 1025px) {
      width: calc(100% - 20rem);
      margin-right: 20rem;
    }
  }

  .process-title {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 4rem;

    h2 {
      ${headlineOne};
      margin: 0;
      margin-bottom: 1rem;
      color: #3a3b3b;
      text-transform: uppercase;
    }

    p {
      ${headlineTwo};
      margin: 0;
      color: #5c5c5c;
    }
  }

  .process-content {
    p {
      ${bodyCopy};
      color: #585858;
    }
  }

  .web-process-steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const WebProcessStep = styled.div`
  width: 100%;
  margin: 2rem auto;

  @media (min-width: 768px) {
    width: calc(33.33% - 1rem);
    margin: 2rem 0.5rem;
  }

  @media (min-width: 1025px) {
    width: calc(33.33% - 4rem);
    margin: 2rem;
  }

  .step-card {
    width: 100%;
    border: solid 0.1rem #c4c4c4;
    box-shadow: 0.2rem 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.16);
  }

  .step-image {
    position: relative;
    width: 100%;
    min-height: 12rem;
  }

  .step-content {
    padding: 2rem;

    @media (min-width: 768px) {
      min-height: 45rem;
    }

    @media (min-width: 1025px) {
      min-height: 35rem;
    }

    h3 {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 160, 1.8)}
      margin: 0;
      margin-bottom: 0.5rem;
      color: #3a2331;
    }

    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 160, 1.8)}
      margin: 0;
      color: #707070;
    }
  }

  .step-process {
    width: 100%;
  }

  .step-icon {
    width: 100%;
    max-width: 7.5rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .step-list {
    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 160, 1.8)}
      margin: 0;
      color: #585858;
    }
  }
`

const WebProcess = () => {
  return (
    <WebProcessSection>
      <StandardWrapper>
        <div className="process-intro">
          <div className="process-title">
            <h2>Web Process</h2>
            <p>Deep dive and a peek behind the curtain</p>
          </div>
          <div className="process-content">
            <p>
              At Switchback Creative, it’s no secret, we love building websites.
              We thought it would be helpful to share what it’s like to do a
              typical web project with us. These are roughly the steps that we
              take to bring your website dream to life.
            </p>
          </div>
        </div>
      </StandardWrapper>
      <div className="process-wrapper">
        <div className="web-process-steps">
          {processItems.map((step, index) => {
            return (
              <WebProcessStep key={index}>
                <div className="step-card">
                  <div className="step-image">{step.bgImg}</div>
                  <div className="step-content">
                    <div>
                      <h3>{step.title}</h3>
                    </div>
                    <div>
                      <p>{step.description}</p>
                    </div>
                  </div>
                </div>
                <div className="step-process">
                  <div className="step-icon">{step.icon}</div>
                  <div className="step-list">
                    {step.steps.map((s, i) => {
                      return (
                        <p key={i} index={i}>
                          {s}
                        </p>
                      )
                    })}
                  </div>
                </div>
              </WebProcessStep>
            )
          })}
        </div>
      </div>
    </WebProcessSection>
  )
}

export default WebProcess

import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

const WebTechGatsby = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          webTechGatsby: file(
            relativePath: { eq: "web-deve-stack_gatsby.jpg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <Img
              fluid={data.webTechGatsby.childImageSharp.fluid}
              alt="Switchback Creative. You know your vision. We Know storytelling."
            />
          </>
        )
      }}
    />
  )
}

export default WebTechGatsby

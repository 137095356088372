import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/Seo/seo"
import HeroImage from "../../components/Shared/HeroImage"
import TechStack from "../../components/PageParts/WebDevelopment/TechStack"
import WebProcess from "../../components/PageParts/WebDevelopment/WebProcess"
import HeroNeed from "../../components/PageParts/WebDevelopment/HeroNeed"

// import HeroWebsite from "../../Elements/Images/HeroWebsite"

const WebDevelopment = props => {
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Web Development Process Deep Dive - Switchback Creative"
        description="It's no secret, we love web development. We thought it would be awesome to share what it is like to do a typical web project with us. Here's our process."
        location={props.location.pathname}
      />
      <HeroImage
        title="website development"
        subTitle="We strive to stay up with all the new and exciting technology, no templates or pre-made websites here."
        bgImg="website"
      />
      <TechStack />
      <WebProcess />
      <HeroNeed />
    </Layout>
  )
}

export default WebDevelopment

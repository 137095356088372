import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import HeroBranding from "../../../Elements/Images/HeroBranding"
import {
  headlineTwo,
  bodyCopy,
  buttonTwo,
  buttonOne,
  colors,
  fontSizer,
} from "../../../Utilities"

const HeroNeedSection = styled.section`
  position: relative;

  .gatsby-image-wrapper {
    min-height: 73rem;
  }

  .hero-content {
    position: absolute;
    top: 0;
    left: 2.5vw;
    right: 2.5vw;
    padding: 2rem 2rem 5rem;
    background: #fff;
    z-index: 5;

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 7.5vw;
      right: 7.5vw;
      padding: 5rem;
      background: #fff;
      z-index: 5;
    }

    @media (min-width: 1025px) {
      left: 10vw;
      right: auto;
      width: 75rem;
    }

    h2 {
      ${headlineTwo};
      color: #5c5c5c;
    }

    p {
      ${bodyCopy};
      color: #585858;
    }

    .link-button {
      margin-top: 5rem;

      a {
        ${buttonTwo};
      }
    }
  }

  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    z-index: 1;
  }

  .hero-stripe {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: ${colors.colorPrimary};
    text-align: center;
    z-index: 25;

    p {
      ${fontSizer(2, 3.6, 76.8, 140, 2)}
      width: 100%;
      max-width: 90rem;
      margin: 0 auto;
      padding: 0;
      color: ${colors.white};
      text-transform: uppercase;
      line-height: 1.25;

      a {
        ${buttonOne};
        ${fontSizer(1.8, 2.4, 76.8, 110, 3)};
        display: block;
        margin: 2rem auto 0;
        line-height: 1.25;

        @media (min-width: 768px) {
          display: inline-block;
          margin: 0 auto;
        }
      }
    }
  }
`

const HeroNeed = () => {
  return (
    <HeroNeedSection>
      <HeroBranding>
        <div className="hero-content">
          <div>
            <h2>So you know the process. Now what?</h2>
          </div>
          <div>
            <p>
              With your understanding of why and how we build custom WordPress
              websites for our clients you are probably wondering what that
              might cost. Simply put, it’s custom for every website, however,
              with this process and the quality outcome you demand for your
              business, our typical website range is $6,000 ~ $15,000. Depending
              on functionality and the details of your project the price goes up
              from there.
            </p>
          </div>
          <div className="link-button">
            <Link to="/contact">Start Now</Link>
          </div>
        </div>
        <div className="hero-overlay" />
        <div className="hero-stripe">
          <p>
            Tell us what you need? <Link to="/contact">Start A Project</Link>
          </p>
        </div>
      </HeroBranding>
    </HeroNeedSection>
  )
}

export default HeroNeed

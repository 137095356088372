import React from 'react'

const LetterA = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296.45 291.04">
        <path
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
          d="M93.34 287.04H4V274.8s14-1.43 19.65-5.41 13.67-14.24 22.78-35S144.67 4 144.67 4h7.69l95.67 229.8s10 25.34 18.8 31.89 25.62 8.54 25.62 8.54v12.81H180.88v-12s21.36.36 25.34-11.32-2.56-24.77-2.56-24.77l-19.41-47.34H88l-23.06 52.72s-6.78 12.2-.43 20.72 29.2 10 29.2 10z"
        />
        <path
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
          d="M137.68 74.39L97.11 167.73 176.27 167.73 137.68 74.39z"
        />
      </svg>
    </div>
  )
}

export default LetterA
